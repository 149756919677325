<template>
  <div class="contaners_pay">
    <!-- <img style="width:100%;height:100%" src="../../assets/backgroundicon.png"/> -->
    <div @click="mineMessage" class="messageTitle_pay">
      <img class="messageIcon_pay" src="../../assets/userPhone1.png" />
      <div>联系客服</div>
    </div>
    <div class="earnings_clone" @click="cloneBtn">
      <img style="width:15px;height:15px" src="../../assets/leftBtn.png" />
      <div>返回</div>
    </div>
    <div class="content_pattern">
      <div class="pattern_text" style="color:#07C160">*
        用户须知：请按要求填写表单信息，表单信息作为受让申请的凭证，在受让前，请仔细阅读
        <span @click="ruleBtn(1)">《申请书》</span>以及<span @click="ruleBtn(2)">《居间协议》</span>
      </div>
      <div class="formSubmit1">
        <div class="formList">
          <div class="offine_title" style="letter-spacing:20px">姓名</div>
          <input v-model="dataForm.userName.value" />
        </div>
        <div class="formList">
          <div class="offine_title">联系方式</div>
          <input v-model="dataForm.phone.value" />
        </div>
        <div class="formList">
          <div class="offine_title">身份证号</div>
          <input v-model="dataForm.identity.value" />
        </div>
        <div class="formList">
          <div class="offine_title">地址信息</div>
          <input v-model="dataForm.address.value" />
        </div>
        <div style="color:#FF3E46" class="error_text">*地址将用作物料收件地址，请仔细确认。</div>
        <div class="formList">
          <div class="offine_title">收件地址</div>
          <textarea v-model="dataForm.consignee_address.value"></textarea>
        </div>
      </div>
      <!-- <div style="color:#fff" class="offine_text">*经系统检测,您的购享仅为:5000,可持有</div>
            <div style="color:#fff" class="offine_text">最大股份为:1000</div>
            <div class="address">收货详细地址:</div>
            <textarea class="shipping">

            </textarea> -->
      <div style="display:flex;">
        <div v-if="iconShow==false" @click="checkoutBtn(1)" class="select_box"></div>
        <div v-else class="select_box" @click="checkoutBtn(2)" style="background:#87CEFA"></div>
        <div class="ruleBtn" style="color:#6D6D6D">我已熟知与查看
          <span @click="ruleBtn(1)">《申请书》</span>
          与
          <span @click="ruleBtn(2)">《居间协议》</span></div>
      </div>
      <!-- <div class="offine_text" style="color:#fff;">您已是会员用户,只能激活股东</div> -->
      <!-- <div class="offine_text"  style="color:#fff;">如不是请<span style="color:#DEB77A">申请股东></span></div> -->
      <div class="office_submit" @click="submit">
        <img src="../../assets/submitBtn.png" />
      </div>
    </div>

    <!-- 签字弹窗 -->
    <div class="signtureShow" v-if="signtureShow" @click="signtureShow">
      <div class="signtureShow_box">
        <catSignature :imgWidth="imgWidth" @signatureBtn="signatureBtn" :imgToken="imgToken" @cloneImg="cloneImg" @cloneBtn="cloneBtns" @imgUblod="imgUblod"></catSignature>
      </div>
    </div>

    <toast v-if="toastShow" :config="configData"></toast>
  </div>
</template>
<script>
// import wx from "weixin-js-sdk";
import * as apiCommon from "@/api/common";
import toast from "@/components/toast";
import { Toast } from "vant";
import Cookies from "js-cookie";
import { putDeal_api } from "@/api/deal";
import catSignature from "../../components/catSignature.vue";
export default {
  data() {
    return {
      date: "",
      show: false,
      // formatDate:"",
      fileList: [],
      dataForm: {
        userName: {
          value: "",
          title: "姓名:",
          text: "请输入姓名",
          color: "#DEB77A"
        },
        phone: {
          value: "",
          title: "联系方式",
          text: "请输入联系方式",
          color: "#DEB77A"
        },
        identity: {
          value: "",
          title: "身份证号",
          text: "请输入身份证号",
          color: "#DEB77A"
        },
        nameImg: {
          value: "",
          title: "签名",
          text: "请输入签名",
          color: "#DEB77A"
        },
        address: {
          value: "",
          title: "地址",
          text: "请输入地址",
          color: "#DEB77A"
        },
        consignee_address: {
          value: "",
          title: "收件人地址",
          text: "请输入收件人地址",
          color: "#DEB77A"
        }
      },
      showPicker: false,

      imgWidth: 645,
      signtureShow: false,
      img: "",
      imgToken: "",
      imgConfig: "{ useCdnDomain: true }",
      dataList: {
        user: {}
      },
      iconShow: false,
      minDate: new Date(2020, 0, 1),
      maxDate: new Date(2025, 10, 1),
      userShow: false,
      configData: {
        title: ""
      },
      toastShow: false,
      userFrom: null,
      queryList: null,
      tableData: ""
    };
  },
  components: {
    catSignature: catSignature,
    toast: toast
  },
  computed: {
    // formatDate(){
    //      return `${new Date().getMonth() + 1}/${new Date().getDate()}`
    //  }
  },
  created() {
    document.title = "信息填写";
    this.queryList = this.$route.query;
    this.getDetails(this.queryList.id);
    this.getToken();
    this.getList();
    if(localStorage.getItem("signature")){
      this.dataForm.nameImg.value = localStorage.getItem("signature")
      this.iconShow = true
    }
    // this.getUser();
  },
  methods: {
    async getDetails(id) {
      let res = await apiCommon.putOrder_api(id);
      if (res.code == 0) {
        this.tableData = res.data;
        this.userFrom = res.data;
        if (res.data.trade_info) {
          this.dataForm.userName.value = res.data.trade_info.userName;
          this.dataForm.phone.value = res.data.trade_info.phone;
          this.dataForm.address.value = res.data.trade_info.address;
          this.dataForm.identity.value = res.data.trade_info.identity;
          this.dataForm.consignee_address.value =
            res.data.trade_info.consignee_address;
        }
        if(res.data.trade_user.union_shareholder_info&&!res.data.trade_info){
          this.dataForm.userName.value = res.data.trade_user.union_shareholder_info.name?res.data.trade_user.union_shareholder_info.name:res.data.trade_user.union_shareholder_info.userName;
          this.dataForm.phone.value = res.data.trade_user.union_shareholder_info.phone;
          this.dataForm.address.value = res.data.trade_user.union_shareholder_info.address;
          this.dataForm.identity.value = res.data.trade_user.union_shareholder_info.identity;
          this.dataForm.consignee_address.value =
            res.data.trade_user.union_shareholder_info.consignee_address;
        }
      }
    },
    cloneBtns() {
      this.signtureShow = false;
    },
    getUser() {
      apiCommon.getuser_api().then(res => {
        if (res.code == 0) {
          console.log(res, "res");
          this.userFrom = res.data;
          this.dataForm.userName.value = res.data.trade_info.userName;
          this.dataForm.phone.value = res.data.trade_info.phone;
          this.dataForm.address.value = res.data.trade_info.address;
          this.dataForm.identity.value = res.data.trade_info.identity;
          this.dataForm.consignee_address.value =
            res.data.trade_info.consignee_address;
        }
      });
    },
    ruleBtn(index) {
      if (this.checked == 1) {
        Cookies.set("dataForm1", JSON.stringify(this.dataForm1));
      } else {
        Cookies.set("dataForm", JSON.stringify(this.dataForm));
      }
      if (index == 1) {
        this.$router.push(
          `./applytext?id=${this.queryList.id}&iconShow=${this.iconShow}&type=1`
        );
      } else {
        this.$router.push(`./contract?id=${this.queryList.id}`);
      }
    },
    historyBtn() {
      if (this.checked == 1) {
        Cookies.set("dataForm1", JSON.stringify(this.dataForm1));
      } else {
        Cookies.set("dataForm", JSON.stringify(this.dataForm));
      }
      this.$router.push("../../audit/index");
    },
    onConfirm1(value) {
      this.dataForm1.sex.value = value;
      this.dataForm.sex.value = value;
      console.log(this.config, "config");
      this.showPicker = false;
    },
    select() {
      this.userShow = true;
    },
    confirm(value) {
      this.userShow = false;
      this.formatDate(value);
    },
    cancel() {
      this.userShow = false;
    },
    cloneBtn() {
      this.$router.go(-1);
    },
    imgBtnClone(index, id) {
      if (index == 1) {
        this.fileList.splice(id, 1);
      } else {
        this.dataForm1.fileList.value.splice(id, 1);
      }
    },
    imgUblod(img) {
      console.log(img, "img");
      localStorage.setItem("signature", img);
      this.dataForm.nameImg.value = img;
      Toast({
        message: "签名成功",
        icon: "error",
        duration: 1000
      });
      this.popShow = true;
      this.iconShow = true;
      this.signtureShow = false;
    },
    signatureBtn() {
      this.toastShow = true;
      this.configData.title = "签名不能为空";
      setTimeout(e => {
        console.log(e);
        this.toastShow = false;
      }, 1000);
    },
    cloneImg() {
      this.signtureShow = false;
      this.dataForm.nameImg.value = "";
    },
    submit() {
      console.log(1);
      let data;
      // console.log(this.dataForm.date,"data")
      data = {
        trade_info: {
          userName: this.dataForm.userName.value,
          phone: this.dataForm.phone.value,
          identity: this.dataForm.identity.value,
          nameImg: this.dataForm.nameImg.value,
          consignee_address: this.dataForm.consignee_address.value,
          address: this.dataForm.address.value,
          signature: this.dataForm.nameImg.value
        }
      };
      //
      for (var j in this.dataForm) {
        if (
          !this.dataForm[j].value &&
          this.dataForm[j] != "consignee_address"
        ) {
          this.toastShow = true;
          this.configData.title = this.dataForm[j].text;
          setTimeout(() => {
            this.toastShow = false;
          }, 1000);
          return false;
        }
      }
      if (!/^1[3456789]\d{9}$/.test(this.dataForm.phone.value)) {
        this.toastShow = true;
        this.configData.title = "请输入正确的手机号";
        setTimeout(() => {
          this.toastShow = false;
        }, 1000);
        return false;
      }
      putDeal_api(data, this.queryList.id).then(res => {
        if (res.code == 0) {
          localStorage.removeItem("signature");
          localStorage.removeItem("signature1")
          this.toastShow = true;
          this.configData.title = "提交成功";
          setTimeout(() => {
            this.toastShow = false;
            this.$router.go(-1);
          }, 1000);
        } else {
          this.toastShow = true;
          this.configData.title = res.error;
          setTimeout(() => {
            this.toastShow = false;
          }, 1000);
        }
      });
    },
    onConfirm(value) {
      console.log(value, "value");
      this.show = false;
      this.formatDate(value);
      // console.log(this.dataForm1.userTime.value,"1111")
    },
    formatDate(data) {
      this.dataForm1.userTime.value = `${data.getFullYear()}-${data.getMonth() +
        1}-${data.getDate()}`;
      this.dataForm.date.value = `${data.getFullYear()}-${data.getMonth() +
        1}-${data.getDate()}`;
      console.log(this.dataForm1.userTime.value);
      this.$forceUpdate();
    },
    oversize() {},
    // 股东激活期权证书上传
    beforeRead(e) {
      console.log(e, "file");
      let img = e;
      this.upload(1, img);
    },
    // 工会激活期权证书上传
    beforeRead1(e) {
      console.log(e, "file");
      let img = e;
      this.upload(2, img);
    },
    async upload(index, img) {
      let fd = new FormData();
      fd.append("token", this.imgToken);
      fd.append("config", this.imgConfig);
      fd.append("file", img);
      console.log(fd, "fd");
      let imgRes = await apiCommon.uploadImg(fd);
      this.imgUrl = "https://cdn.health.healthplatform.xyz/" + imgRes.key;
      console.log(this.imgUrl, "1111");
      if (index == 1) {
        this.fileList.push({ url: this.imgUrl });
      } else if (index == 2) {
        this.dataForm1.fileList.value = [{ url: this.imgUrl }];
      } else {
        this.fileList2 = [{ url: this.imgUrl }];
      }
      this.$forceUpdate();
    },
    async getToken() {
      let res = await apiCommon.getImgToken();
      if (!res || typeof res === "string" || res.error)
        return this.$toast.fail(res ? res.error || res : "获取七牛云信息失败!");
      this.imgToken = res.data;
      console.log(this.imgToken, "1223131");
    },
    mineMessage() {
      // wx.closeWindow();
      this.$router.push("../lador/service");
    },
    checkoutBtn(index) {
      if (index == 1) {
        // this.iconShow = true;
        this.signtureShow = true;
      } else {
        this.iconShow = false;
      }
      this.signtureShow = true;
      this.$forceUpdate();
    },
    async getList() {
      let res = await apiCommon.getUserInfo_api();
      if (res.code == 0) {
        console.log(1);
        this.dataList = res.data;
      }
    }
  }
};
</script>
<style type="text/css">
.van-toast {
  width: 300px;
  min-height: 120px;
}
.van-toast__text {
  font-size: 35px;
}
.van-icon {
  font-size: 40px;
}
.van-dialog {
  width: 500px;
  font-size: 35px !important;
}
.van-dialog__message {
  font-size: 25px;
  line-height: 40px;
}
.van-picker {
  width: 750px;
  height: 600px;
  position: fixed;
  bottom: 0;
  left: 0;
  font-size: 25px;
  z-index: 9999;
}

.earnings_clone {
  margin-left: 31px;
  color: #000000;
  font-size: 15px;
  font-weight: 800;
  margin-top: 30px;
}

.selectTimes {
  width: 348px;
  height: 54px;
  border: 2px solid #deb77a;
  opacity: 1;
  border-radius: 5px;
  padding-left: 20px;
  box-sizing: border-box;
}

.van-picker__cancel,
.van-picker__confirm {
  font-size: 30px !important;
}
.signtureShow {
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0;
  left: 0;
}
.signtureShow_box {
  width: 80%;
  background-color: #fff;
  margin: 150px auto 0;
  border-radius: 15px;
  padding-bottom: 20px;
}
.van-calendar__header-title {
  font-size: 25px;
}
.content_pattern .van-radio__label {
  color: #deb77a !important;
}
.messageTitle_pay .messageIcon_pay {
  width: 36px;
  height: 36px;
  margin-right: 20px;
}
/* .signtureShow {
  position: fixed;
  left: 30px;
  top: 100px;
  width: 645px;
  height: 900px;
  background-color: #fff;
} */
.messageTitle_pay {
  text-align: right;
  margin-right: 20px;
  font-size: 24px;
  margin-top: 20px;
  position: absolute;
  z-index: 10;
  top: -13px;
  right: 20px;
  color: #000;
}
.contanter_pay {
  width: 100%;
  position: relative;
  padding: 30px 71px 0 51px;
  box-sizing: border-box;
}
.clonePay {
  font-size: 22px;
  font-weight: 800;
}
input {
  padding-left: 20px;
  font-size: 25px;
}
.contaners_share {
  width: 100%;
  height: 2000px;
  position: relative;
  font-size: 30px;
  color: #2e2e30;
  padding-bottom: 40px;
}
.content_pattern {
  /* position: absolute; */
  top: 0;
  left: 0;
  /* width:666px; */
  /* padding: 40px 45px; */
  margin-top: 50px;
}
.messageTitle13 .messageIcon {
  width: 36px;
  height: 36px;
  margin-right: 20px;
}
.messageTitle13 {
  text-align: right;
  margin-right: 20px;
  font-size: 20px;
  color: #fff;
  position: absolute;
  top: 20px;
  right: 10px;
}
.header_contract {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
}
.header_contract div {
  display: flex;
}
.header_contract img {
  width: 28px;
  height: 28px;
  margin-right: 10px;
}
.contranct_text1 {
  font-size: 30px;
  font-family: PingFang SC;
  font-weight: bold;
  color: #ffffff;
  margin-top: 20px;
}
.contranct_text {
  font-size: 25px;
  font-family: PingFang SC;
  font-weight: bold;
  color: #deb77a;
  opacity: 1;
  margin-top: 25px;
}
.contranct_box {
  width: 515px;
  height: 567px;
  margin: 291px auto 0;
  position: relative;
}
.contranct_box img {
  width: 100%;
  height: 100%;
}

.formSubmit1 {
  margin-top: 71px;
}
.formList input {
  background-color: transparent;
  border: 1px solid #c5c2c2;
  width: 348px;
  height: 54px;
  /* position: relative; */
}
.box_clone {
  width: 20px;
  /* height:20px; */
  border-radius: 20px;
  background-color: #fff;
  position: absolute;
  top: 0;
  right: 0;
}
.box_clone .imgs {
  width: 20px;
  height: 20px;
  border-radius: 20px;
}
.patterm input {
  border: none;
  width: 153px;
  border-bottom: 2px solid #c5c2c2;
  background-color: transparent;
  margin-right: 30px;
}
.patterm {
  font-size: 22px;
  color: #fff;
  margin-bottom: 30px;
}
.van-cell {
  font-size: 25px;
  width: 560px;
  height: 60px;
  background-color: transparent;
  margin-left: 30px;
  margin-bottom: 30px;
  padding: 0;
  /* border: 1px solid #DEB77A;
    width: 348px;
    height: 54px; */
}
.van-picker-column {
  font-size: 25px;
}
.ac_history {
  font-size: 25px;
  color: #deb77a;
  text-align: center;
}
.van-field__control {
  height: 60px;
}
.van-field__label {
  margin-left: 27px;
  font-size: 25px;
  letter-spacing: 40px;
  color: #2e2e30;
  width: 145px;
  line-height: 50px;
  margin-right: 10px;
}
.van-field__value {
  width: 349px;
  /* height: 45px; */
  /* border: 1px solid #DEB77A; */
  opacity: 1;
  border-radius: 0px;
}
.van-field__control {
  width: 370px;
  /* height: 45px; */
  border: 1px solid #deb77a;
  color: #deb77a;
  opacity: 1;
  border-radius: 0px;
  padding-left: 20px;
  box-sizing: border-box;
}
.formList {
  display: flex;
  line-height: 54px;
  /* margin-bottom: 40px; */
  margin: 0 60px 40px;
}
.offine_title {
  width: 150px;
  letter-spacing: 2px;
  font-size: 25px;
}
.offine_text {
  font-size: 22px;
  margin-bottom: 20px;
  text-align: center;
}
.shipping {
  width: 519px;
  height: 161px;
  border: 2px solid #deb77a;
  opacity: 1;
  border-radius: 5px;
  background-color: transparent;
  margin-bottom: 40px;
}
.address {
  margin: 48px auto 24px;
}

.ruleBtn {
  color: #deb77a !important;
  margin-left: 20px;
  font-size: 25px;
}
.ruleBtn span {
  color: #409eff;
}
.select_box {
  width: 32px;
  height: 32px;
  border: 1px solid #deb77a;
  opacity: 1;
  border-radius: 0px;
  margin-left: 100px;
}
.office_submit {
  width: 206px;
  height: 68px;
  margin: 10px auto 0;
}
.office_submit img {
  width: 100%;
  height: 100%;
}
.pattern_title {
  font-size: 35px;
  margin-top: 30px;
}
.pattern_text {
  margin: 35px auto;
  font-size: 25px;
  width: 590px;
  line-height: 35px;
}
.pattern_text span {
  color: #ff3e46;
}
.select_box1 {
  margin: 0 auto;
  width: 500px;
  font-size: 25px;
}

.text1 {
  color: #fff;
  font-size: 22px;
  margin: 28px auto 0;
  text-align: center;
}

.text2 {
  color: #fff;
  font-size: 22px;
  margin: 28px auto 32px;
  /* text-align: ce */
}
.selectBtn {
  font-size: 22px;
  color: #deb77a;
}
.selectText {
  color: #deb77a;
}

.item_list {
  margin-left: 200px;
}
.imgClass {
  position: relative;
  display: flex;
}
.imgClass img {
  width: 80px;
  height: 80px;
}

textarea {
  width: 300px;
  height: 140px;
  border: 2px solid #c5c2c2;
  opacity: 1;
  font-size: 25px;
  border-radius: 10px;
}
.error_text {
  font-size: 24px;
  margin-left: 200px;
  margin-bottom: 10px;
}
</style>